/*
 * Copyright 2024. Elie Nicolas - All Rights Reserved.
 * This file is part of the comuta project.
 */

import {Route, Routes} from "react-router-dom";
import {NotFoundPage} from "./pages/errors/NotFoundPage";

export function AppRoutes() {
	return (<Routes>
		<Route path={'*'} element={<NotFoundPage/>}></Route>
	</Routes>);
}
