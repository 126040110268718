/*
 * Copyright 2024. Elie Nicolas - All Rights Reserved.
 * This file is part of the comuta project.
 */

import React from 'react';
import './styles/styles.scss';
import {BrowserRouter} from "react-router-dom";
import {AppRoutes} from "./AppRoutes";
import {AppBar} from "./components/AppBar/AppBar";

function App() {
  return (
    <div className={'w-full h-full flex flex-col'}>
      <AppBar></AppBar>
      <div className="w-full grow overflow-hidden background">
        <BrowserRouter>
          <AppRoutes></AppRoutes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
