/*
 * Copyright 2024. Elie Nicolas - All Rights Reserved.
 * This file is part of the comuta project.
 */

import * as i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from "i18next-intervalplural-postprocessor";
import {initReactI18next} from 'react-i18next';

import english from './english.json';
import french from './french.json';

const translations = {
	en: {translation: english},
	fr: {translation: french},
};


i18n.use(LanguageDetector).use(intervalPlural).use(initReactI18next).init({
	fallbackLng: 'en',
	compatibilityJSON: 'v3',
	resources: translations,
	interpolation: {
		escapeValue: false,
	},
	keySeparator: '.'
});

export default i18n;
