/*
 * Copyright 2024. Elie Nicolas - All Rights Reserved.
 * This file is part of the comuta project.
 */

import {useTranslation} from "react-i18next";
import {CmButton} from "comui";

export function NotFoundPage() {
  const {t} = useTranslation();
  return (
    <div className={'w-full h-full flex flex-col justify-center items-center gap-6'}>
      <h1>{t('PAGES.ERRORS.NOT_FOUND.TITLE')}</h1>
      <p className={'max-w-[600px] text-center font-bold text-lg'}>{t('PAGES.ERRORS.NOT_FOUND.MESSAGE')}</p>
      <CmButton type={'accent'}>GO BACK HOME</CmButton>
    </div>
  );
}
