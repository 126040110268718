/*
 * Copyright 2024. Elie Nicolas - All Rights Reserved.
 * This file is part of the comuta project.
 */

import {CmButton} from "comui";

export function AppBar() {
  return (
    <div className={'w-full h-[80px] block bg-primary border-b-[0.5px] border-stroke shadow-xl z-40 ' +
      'bg-gradient-to-l from-accent/10'}>
      <div className={'h-[35px] px-3 flex justify-between items-center'}>
        <span className={'text-lg font-bold'}>COMUTA</span>
      </div>
      <div className={'h-[45px] px-3 flex justify-between items-center'}>
        <div></div>
        <div className={'flex gap-3'}>
          <CmButton>CREATE ACCOUNT</CmButton>
          <CmButton type={'accent'}>SIGN-IN</CmButton>
        </div>
      </div>
    </div>
  )
}
